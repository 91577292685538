import React, { useEffect } from 'react';
import "../Style/Footer.css";
import logo from "../image/KanakLandscapeLogo.png";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoLocationSharp } from "react-icons/io5";
import { IoMdCall } from "react-icons/io";
import { Link, useLocation } from 'react-router-dom';

function Footer() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className='footer_container'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className="footer_subcon col-lg-3 col-md-6 col-sm-12  d-flex justify-content-center     pt-5">
            <div className='w-75'>

              <img src={logo} alt="" style={{ width: "15rem" }} />
              <p style={{ textAlign: "start", paddingLeft: "0.5rem" }} className=' text-white'>
                Kanak Drishti Infotech Pvt.Ltd.
              </p>
              <p style={{ textAlign: "start", paddingLeft: "0.5rem" }} className=' text-white'>
                <IoLocationSharp /> A-88 first floor, A Block, Sector 4, Noida, Uttar Pradesh 201301
              </p>
              <p style={{ textAlign: "start", paddingLeft: "0.5rem" }} className='m-0 text-white' >
                <IoMdCall />
                <a style={{ color: "white" }} href="tel:+919911210174">
                  +91 9911210174 ,
                </a>
              </p>
              <p style={{ textAlign: "start", paddingLeft: "0.5rem" }} className='m-0 text-white' >
                <a style={{ color: "white" }} href="tel:+919625781262">
                  +91 9625781262
                </a>
              </p>
            </div>
          </div>
          <div className="footer_subcon ps-5 col-lg-3 col-md-6 col-sm-12  d-flex justify-content-center    pt-5">
            <div className="w-75">

              <h4 className='text-white text-start '>Popular Links</h4>
              <p className='text-white text-start'><FaArrowRightLong /> <Link>Home</Link></p>
              <p className='text-white text-start'><FaArrowRightLong /> <Link to="/aboutus">About Us</Link> </p>
              <p className='text-white text-start'><FaArrowRightLong /> <a href="https://kanakdrishtiinfo.com/contact">Contact Us </a></p>
              <p className='text-white text-start'><FaArrowRightLong /> <a href="https://kanakdrishtiinfo.com/service">Our Services</a></p>
              <p className='text-white text-start'><FaArrowRightLong /> <a href="https://kanakdrishtiinfo.com/contact">Request Quote</a></p>
            </div>
          </div>
          <div className="footer_subcon col-lg-3 col-md-6 col-sm-12  d-flex justify-content-center ps-5 pt-5">
            <div className="w-75">
              <h4 className='text-white  text-start'>Quick Links</h4>
              <p className='text-white text-start'><FaArrowRightLong /> <a href="https://kanakdrishtiinfo.com/client">Clients</a></p>
              <p className='text-white text-start'><FaArrowRightLong /> <a href="https://kanakdrishtiinfo.com/work">Portfolio</a></p>
              <p className='text-white text-start'><FaArrowRightLong /> <a href="https://kanakdrishtiinfo.com/privacy-policy">Privacy Policy</a></p>
              <p className='text-white text-start'><FaArrowRightLong /> <a href="https://kanakdrishtiinfo.com/term-conditions">Terms & Condition</a></p>
            </div>

          </div>
          <div className="footer_subcon col-lg-3 col-md-6 col-sm-12   d-flex justify-content-center pt-5">
            <div className="w-75 ">

              <h4 className='text-white'>Get In Touch</h4>
              <iframe
                className='mt-3'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.5278793545435!2d77.32053447409206!3d28.583936586288406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5acd86d2d77%3A0x34955dbaf07baf15!2sKanak%20Drishti%20Infotech%20Pvt.Ltd.!5e0!3m2!1sen!2sin!4v1718530766114!5m2!1sen!2sin"
                style={{ borderRadius: "1rem", width: "13rem" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>

      </div>
      <hr className='text-white' />
      <div className='container-fluid'>

        <div className='row justify-content-between'>
          <div className='col-lg-4 col-sm-12 col-md-6 '>
            <p className='text-white '>
              &copy;
              All Rights Reserved. <a style={{ textDecoration: "none", color: "white", fontWeight: "bold" }} href="https://kanakdrishtiinfo.com/">Kanak Drishti Infotech Pvt. Ltd.</a>
            </p>
          </div>
          <div className='col-lg-5 col-sm-12 col-md-6'>
            <p className='text-white text-end '>
              Designed & Developed by  <a style={{ textDecoration: "none", color: "white", fontWeight: "bold" }} href="https://kanakdrishtiinfo.com/">Kanak Drishti Infotech Pvt. Ltd.</a>
            </p>
          </div>
        </div>
      </div>


    </div>
  )
}

export default Footer