import React from 'react';
import video from "../image/video.mp4";



function Maincon2() {
  return (
    <>
      <div className='container-fluid mt-5' style={{}}>

    <div className='mb-5 row'>
      <div className="col-lg-6 m-0 p-0">
      <video style={{height:"100%",width:"100%",padding:"0px",margin:"0px"}} loop  autoPlay={true} muted controls={false}>
          <source src={video} type="video/mp4" />
        </video>
      </div>
      <div className="col-lg-6 m-0 p-0">
          <h3 className='text-center'> <span style={{color:"#003bb2",borderBottom:"2px solid #003bb2"}}>About Us</span></h3>
          <p className='text-start mt-2 '>For 10 years, Kanak Drishti has empowered businesses in India with customer-centric IT solutions. We combine cutting-edge expertise with unwavering dedication to drive your business growth exponentially.</p>
          <p className='text-start'>Kanak Drishti is a leading IT company that specializes in providing customized solutions for businesses. From web design to custom software development, our team of experts is committed to helping you achieve your goals. Contact us today to get started.</p>
          <p className='text-start '>For 10 years, Kanak Drishti has empowered businesses in India with customer-centric IT solutions. We combine cutting-edge expertise with unwavering dedication to drive your business growth exponentially.</p>
          <p className='text-start'>Kanak Drishti is a leading IT company that specializes in providing customized solutions for businesses. From web design to custom software development, our team of experts is committed to helping you achieve your goals. Contact us today to get started.</p>
      </div>
    </div>
      </div>
    </>
  )
}

export default Maincon2