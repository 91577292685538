import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Header from './Components/Header';
import { Outlet, Link } from "react-router-dom";
import Footer from './Components/Footer';
import Maincon from './Components/Maincon';
import Aboutus from "./Components/Aboutus";
import Headerpre from "./Components/Headerpre";
import DigitalMarketing from "./Components/DigitalMarketing";
import DigitalMarketingUP from "./Components/DigitalMarketingUP";
import DigitalMarketingMP from "./Components/DigitalMarketingMP";
import DigitalMarketingRajsthan from "./Components/DigitalMarketingRajsthan";
import DigitalMarketingMaharashtra from "./Components/DigitalMarketingMaharashtra";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Headerpre />
      <Header />
      <Routes>
        <Route path="/" element={<Maincon />} />
        <Route  path="/aboutus" element={<Aboutus/>} />
        <Route  path="/digital-marketing" element={<DigitalMarketing/>} />
        <Route  path="/digital-marketing-service-uttar-pradesh" element={<DigitalMarketingUP/>} />
        <Route  path="/digital-marketing-service-madhya-pradesh" element={<DigitalMarketingMP/>} />
        <Route  path="/digital-marketing-service-rajasthan" element={<DigitalMarketingRajsthan/>} />
        <Route  path="/digital-marketing-service-maharashtra" element={<DigitalMarketingMaharashtra/>} />
        <Route  path="/contactus" element={<Aboutus/>} />
      </Routes>
      <Outlet/>
      <Footer />
    </BrowserRouter>
    </div>
  );
}

export default App;
