import React from 'react';
import "../Style/Style.css"


function Headerpre() {
    
    const onButtonClick = () => {
      fetch("Kanak_Catalogue.pdf").then((response) => {
          response.blob().then((blob) => {
              const fileURL =
                  window.URL.createObjectURL(blob);
                   
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = "Kanak_Catalogue.pdf";
              alink.click();
          });
      });
  };
  return (
    <div className='d-flex justify-content-center align-items'>
        <div className='bg-primary headerpre d-flex justify-content-between px-3'>
            <span className='telnumber'><a href="tel:+919911210174">+91 9911210174</a> , <a href="tel:+919625781262">+91 9625781262</a></span>

            <button className='btnclass btn btn-sm' onClick={onButtonClick}>
            Download Brochure
</button>
        </div>

    </div>
  )
}

export default Headerpre