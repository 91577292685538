import React from 'react'
import CarouselHead from './CarouselHead'
import Maincon2 from './Maincon2'
import Maincon1 from './Maincon1'
import Maincon3 from './Maincon3'
import Maincon4 from './Maincon4'

function Maincon() {
  return (
    <>
 <CarouselHead />
      <Maincon2 />
      <Maincon1 />
      <Maincon3 />
      <Maincon4 />
    </>
  )
}

export default Maincon