import React from 'react'
import digi from "../image/degital_marketing.png"
import grap from "../image/graphic_design.png"
import ecom from "../image/e-commerce.png"
import uiux from "../image/ui-ux.png"
import app from "../image/app_developement.png"
import web from "../image/web_developement.png"
import soft from "../image/software_developement.png"
import giffile from "../image/Animation - 1712577007214.gif" 

import "../Style/Maincon1.css"

function Maincon1() {
  return (
        <>
        <h1 className='mb-5 text-center'> <span style={{color:"#003bb2",borderBottom:"4px solid #003bb2"}}>Our Services</span> </h1>
        <div className="container pb-3">
            <div className="row justify-content-center">
            <div className="services_container border col-lg-4 col-md-6 col-sm-4 col-12 ">
                   <div className='service_container_subcon'>
                    <img src={web} alt="imge not load" className='service_container_subcon_img' />
                   </div>
                   <div className='my-3'>
                    <h3 className="text-center">Website Developement</h3>
                    <br />
                    <div className='d-flex justify-content-center'>
                     <a href="https://kanakdrishtiinfo.com/web-mobile-app-development-solutions">
                    <button className='btn btn-outline-primary btn-sm'>View More</button>
                     </a>
                    </div>
          
                   </div>
                </div>
                <div className="services_container border col-lg-4 col-md-6 col-sm-4 col-12 ">
                   <div className='service_container_subcon'>
                    <img src={ecom} alt="imge not load" className='service_container_subcon_img' />
                   </div>
                   <div className='my-3'>
                    <h3 className="text-center">E-Commerce</h3>
                    <br />
                    <div className='d-flex justify-content-center'>
                     <a href="https://kanakdrishtiinfo.com/e-commerce-solutions">
                    <button className='btn btn-outline-light btn-sm'>View More</button>
                     </a>
                   </div>
                   </div>
                </div>
                <div className="services_container border col-lg-4 col-md-6 col-sm-4 col-12 ">
                   <div className='service_container_subcon'>
                    <img src={soft} alt="imge not load" className='service_container_subcon_img' />
                   </div>
                   <div className='my-3'>
                    <h3 className="text-center">Software Developement</h3>
                    <br />
                    <div className='d-flex justify-content-center'>
                     <a href="https://kanakdrishtiinfo.com/customised-software-solutions">
                    <button className='btn btn-outline-primary btn-sm'>View More</button>
                     </a>
                   </div>
                   </div>
                </div>
                <div className="services_container border col-lg-4 col-md-6 col-sm-4 col-12 ">
                   <div className='service_container_subcon'>
                    <img src={digi} alt="imge not load" className='service_container_subcon_img' />
                   </div>
                   <div className='my-3'>
                    <h3 className="text-center">Degital Marketing</h3>
                    <br />
                    <div className='d-flex justify-content-center'>
                     <a href="https://kanakdrishtiinfo.com/digital-marketing-services">
                    <button className='btn btn-outline-light btn-sm'>View More</button>
                     </a>
                   </div>
                   </div>
                </div>
                <div className="services_container border col-lg-4 col-md-6 col-sm-4 col-12 ">
                   <div className='service_container_subcon'>
                    <img src={grap} alt="imge not load" className='service_container_subcon_img' />
                   </div>
                   <div className='my-3'>
                    <h3 className="text-center">Graphic Designing</h3>
                    <br />
                    <div className='d-flex justify-content-center'>
                     <a href="https://kanakdrishtiinfo.com/graphic-design-services">
                    <button className='btn btn-outline-primary btn-sm'>View More</button>
                     </a>
                   </div>
                   </div>
                </div>
                
                <div className="services_container border col-lg-4 col-md-6 col-sm-4 col-12 ">
                   <div className='service_container_subcon'>
                    <img src={uiux} alt="imge not load" className='service_container_subcon_img' />
                   </div>
                   <div className='my-3'>
                    <h3 className="text-center">UI-UX Designing</h3>
                    <br />
                    <div className='d-flex justify-content-center'>
                     <a href="https://kanakdrishtiinfo.com/ui-ux-solutions">
                    <button className='btn btn-outline-light btn-sm'>View More</button>
                     </a>
                   </div>
                   </div>
                </div>
                <div className="services_container border col-lg-4 col-md-6 col-sm-4 col-12 ">
                   <div className='service_container_subcon'>
                    <img src={app} alt="imge not load" className='service_container_subcon_img' />
                   </div>
                   <div className='my-3'>
                    <h3 className="text-center">App Developement</h3>
                    <br />
                    <div className='d-flex justify-content-center'>
                     <a href="https://kanakdrishtiinfo.com/web-mobile-app-development-solutions">
                    <button className='btn btn-outline-primary btn-sm'>View More</button>
                     </a>
                   </div>
                   </div>
                </div>
             
                {/* <div>
                  <img src={giffile} style={{width:"20rem",height:"20rem"}} alt="" />
                </div> */}
               
                

            </div>  
        </div>
        </>
  )
}

export default Maincon1