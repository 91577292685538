import React, { useState } from "react";

function Aboutus() {
  
  return (
    <div className=" container">
      <h1 className="text-center" style={{ color: "#003bb2" }}>
        About Kanak Drishti Infotech Pvt Ltd
      </h1>
      <p>
        Kanak Drishti Infotech Pvt Ltd is a leading digital marketing solution
        provider based in Noida, dedicated to empowering businesses with
        innovative strategies and cutting-edge technologies. Established with a
        vision to transform the digital landscape, we have been at the forefront
        of delivering exceptional services that drive tangible results for our
        clients.
      </p>
      <h2 style={{ color: "#003bb2" }}>Our Journey</h2>
      <p>
        Founded by a team of passionate professionals with extensive experience
        in digital marketing, design, and development, Kanak Drishti Infotech
        Pvt Ltd began its journey with a commitment to excellence and a
        relentless pursuit of innovation. Over the years, we have grown from a
        small startup to a trusted partner for businesses of all sizes, helping
        them navigate the complexities of the digital world and achieve their
        goals with confidence.
      </p>
      <h2 style={{ color: "#003bb2" }}>Our Mission</h2>
      <p>
        At Kanak Drishti Infotech Pvt Ltd, our mission is simple: to empower
        businesses with the tools, strategies, and expertise they need to
        succeed in the digital age. We believe in the power of innovation,
        collaboration, and customer-centricity, and strive to deliver
        unparalleled value to our clients through our comprehensive range of
        services.
      </p>
      <h2 style={{ color: "#003bb2" }}>Our Values</h2>
      <p>
        <strong style={{ color: "#003bb2" }}>Excellence:</strong> We are
        committed to the growth of employees and clients. So we are delivering
        excellence in everything we do, from the quality of our work to the
        level of service we provide to our clients.
      </p>
      <p>
        <strong style={{ color: "#003bb2" }}>Innovation:</strong> We embrace
        innovation as a driving force for growth and success, constantly pushing
        the boundaries of what's possible in the digital realm.
      </p>
      <p>
        <strong style={{ color: "#003bb2" }}>Integrity:</strong> We conduct our
        business with honesty, integrity, and transparency, building trust and
        credibility with our clients, partners, and stakeholders.
      </p>
      <p>
        <strong style={{ color: "#003bb2" }}>Collaboration:</strong> We believe
        in the power of collaboration and teamwork, working closely with our
        clients to understand their needs and goals and achieve shared success.
      </p>
      <p>
        <strong style={{ color: "#003bb2" }}>Continuous Learning:</strong> We
        foster a culture of continuous learning and professional development,
        empowering our team to stay ahead of the curve and deliver best-in-class
        solutions to our clients.
      </p>
      <h2 style={{ color: "#003bb2" }}>Our Team</h2>
      <p>
        Our team comprises talented individuals with diverse backgrounds and
        skill sets, united by a common passion for digital innovation and client
        success. From digital marketers and graphic designers to developers and
        project managers, each member of our team brings unique expertise and
        insights to the table, enabling us to deliver holistic solutions that
        drive results.
      </p>
      <h2 style={{ color: "#003bb2" }}>Why Choose Us?</h2>
      <p>
        <strong style={{ color: "#003bb2" }}>Proven Track Record:</strong> With
        a track record of success spanning numerous industries and verticals, we
        have the experience and expertise to deliver results that exceed
        expectations.
      </p>
      <p>
        <strong style={{ color: "#003bb2" }}>Customized Solutions:</strong> We
        understand that every business is unique, which is why we offer
        customized solutions tailored to your specific needs, goals, and budget.
      </p>
      <p>
        <strong style={{ color: "#003bb2" }}>Customer-Centric Approach:</strong>{" "}
        We prioritize the needs and goals of our clients above all else,
        ensuring that every project is driven by a deep understanding of their
        business and objectives.
      </p>
      <p>
        <strong style={{ color: "#003bb2" }}>Transparent Communication:</strong>{" "}
        We believe in open and transparent communication, keeping our clients
        informed and involved throughout every step of the process.
      </p>
      <p>
        <strong style={{ color: "#003bb2" }}>Commitment to Excellence:</strong>{" "}
        We are committed to delivering excellence in everything we do, from the
        quality of our work to the level of service we provide to our clients.
      </p>
      <h2 style={{ color: "#003bb2" }}>Let's Connect</h2>
      <p>
        Ready to take your business to the next level? Get in touch with us
        today to learn more about how Kanak Drishti Infotech Pvt Ltd can help
        you achieve your digital marketing and development goals. Whether you're
        a startup looking to establish your online presence or an enterprise
        seeking to drive growth and innovation, we have the expertise and
        resources to make it happen. Let's collaborate to turn your vision into
        reality.
      </p>
    </div>
  );
}

export default Aboutus;
