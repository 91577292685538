import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Example from './Example';



function CarouselHead() {
  return (
    <div>

<Carousel interval={1000} style={{height:"35rem"}} fade controls={false}>
      <Carousel.Item>
        <Carousel.Caption className=''>
          <h1 style={{color:"black",marginTop:"-23rem",fontSize:"clamp(2rem,7vw,4rem)"}}>Custom IT Solutions and Software Services - KDIPL</h1>
          <p style={{color:"black"}}>THE BEST IT COMPANY IN NOIDA: EXPERT SOLUTIONS FOR YOUR BUSINESS</p>
        </Carousel.Caption>
        <Example text="First slide" />
      </Carousel.Item>
      <Carousel.Item >
        <Example text="Second slide" />
        <Carousel.Caption>
          <h1 style={{color:"black",marginTop:"-23rem",fontSize:"clamp(2rem,7vw,4rem)"}}>Expert IT Solutions, Exceptional Results</h1>
          <p style={{color:"black"}}>TRANSFORM YOUR BUSINESS WITH THE LEADING IT COMPANY IN NOIDA</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Example text="Third slide" />
        <Carousel.Caption className=' ' >
          <h1  style={{color:"black",marginTop:"-23rem",fontSize:"clamp(2rem,7vw,4rem)"}}>Transforming Ideas into Stunning Websites</h1>
          <p className='' style={{color:"black"}}>
          Our team of experienced developers and designers are here to turn your vision into reality.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>
  )
}

export default CarouselHead