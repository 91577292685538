import React,{useEffect} from "react";
import {Link,useLocation } from "react-router-dom";
import logo from "../image/KanakLandscapeLogo.png";
import "../Style/Header.css";
function Header() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <nav className="navbar navbar-expand-md  bsb-navbar bsb-navbar-hover border-bottom m-0 bsb-navbar-caret">
      <div className="container">
        <a className="navbar-brand" href="index.html">
          <img src={logo} alt="BootstrapBrain Logo" width={190} height={44} />
        </a>
        <button
          className="navbar-toggler border-0"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex={-1}
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              Menu
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-end flex-grow-1">
              <li className="nav-item">
                <Link to="/" className="nav-link active" aria-current="page">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/aboutus" className="nav-link active" aria-current="page">
                  About
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#!"
                  id="accountDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </a>
                <ul
                  className="dropdown-menu border-0 shadow bsb-zoomIn"
                  aria-labelledby="accountDropdown"
                >
                  <li>
                    <Link className="dropdown-item" to="/digital-marketing">
                    Digital Marketing
                    </Link>
                  </li>
                  <li>
                    <a className="dropdown-item" href="https://kanakdrishtiinfo.com/graphic-design-services">
                    Graphic Designing
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="https://kanakdrishtiinfo.com/e-commerce-solutions">
                    E-Commerce
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="https://kanakdrishtiinfo.com/web-mobile-app-development-solutions">
                      Web Developement
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="https://kanakdrishtiinfo.com/web-mobile-app-development-solutions">
                      App Developement
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="https://kanakdrishtiinfo.com/ui-ux-solutions">
                      UI-UX Designing
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="https://kanakdrishtiinfo.com/customised-software-solutions">
                    Software Developement
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#!"
                  id="accountDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Packages
                </a>
                <ul
                  className="dropdown-menu border-0 shadow bsb-zoomIn"
                  aria-labelledby="accountDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="https://kanakdrishtiinfo.com/best-ppc-packages-pricing">
                      PPC
                    </a>
                  </li>
                 
                  <li>
                    <a className="dropdown-item" href="https://kanakdrishtiinfo.com/best-seo-service-packages-pricing">
                      SEO
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="https://kanakdrishtiinfo.com/best-smo-service-packages-pricing">
                      SMO
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="https://kanakdrishtiinfo.com/best-graphic-designing-packages-pricing">
                      Graphic Design
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="https://kanakdrishtiinfo.com/best-web-development-packages-pricing">
                      Website Developement
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="https://kanakdrishtiinfo.com/best-mobile-app-development-packages-pricing">
                    Mobile App / Web App
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="https://kanakdrishtiinfo.com/work">
                  Portfolio
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="https://kanakdrishtiinfo.com/kanak-drishti-blog">
                  Blogs
                </a>
              </li>
             
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="https://kanakdrishtiinfo.com/client">
                  Clients
                </a>
              </li>
              <li className="nav-item">
                <a href="https://kanakdrishtiinfo.com/contact">
                <button className=" btn btn-outline-primary btn-sm" style={{marginTop:"1.3rem"}} aria-current="page" href="#!">
                  Contact Us
                </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
