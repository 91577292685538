import React from 'react'
import "../Style/Maincon3.css";
import { CiGlobe } from "react-icons/ci";
import { HiLightBulb } from "react-icons/hi";
import { IoSettingsOutline } from "react-icons/io5";
import { FaRegThumbsUp } from "react-icons/fa";

function Maincon3() {
  return (
    <div className='Maincon3_container'>
        <h2 className='pt-3 text-center' style={{color:"white"}}>
        Why Choose Kanak Drishti Infotech Pvt Ltd?
        </h2>
        <div className='container'>
            <div className='row justify-content-center flex-wrap'>
                <div className='Maincon3_container_sub'>
                    <div className='my-3 text-center'>
                        <span style={{borderRadius:"20rem", backgroundColor:"#003bb2",paddingTop:"1.3rem",paddingBottom:"1.5rem",paddingLeft:"1rem",paddingRight:"1rem"}}>
                            <CiGlobe fontSize={40} color='white' />
                        </span>
                    </div>
                    <h3 style={{color:"#003bb2"}} className='text-center'>Expertise</h3>
                    <p className='text-center' >
                     With years of experience in the industry, we have the knowledge and expertise to deliver results-driven solutions that meet your business objectives.
                    </p>
                </div>
                <div className='Maincon3_container_sub'>
                <div className='my-3 text-center'>
                        <span style={{borderRadius:"20rem", backgroundColor:"#003bb2",paddingTop:"1.3rem",paddingBottom:"1.5rem",paddingLeft:"1rem",paddingRight:"1rem"}}>
                            <HiLightBulb fontSize={40} color='white' />
                        </span>
                    </div>
                <h3 style={{color:"#003bb2"}} className='text-center'>Innovation</h3>
                    <p className='text-center' >
                 We stay ahead of the curve by embracing the latest technologies and trends in digital marketing and software development, ensuring that your business remains competitive.
                   </p>
                </div>
                <div className='Maincon3_container_sub'>
                <div className='my-3 text-center'>
                        <span style={{borderRadius:"20rem", backgroundColor:"#003bb2",paddingTop:"1.3rem",paddingBottom:"1.5rem",paddingLeft:"1rem",paddingRight:"1rem"}}>
                            <IoSettingsOutline fontSize={40} color='white' />
                        </span>
                    </div>
                <h3 style={{color:"#003bb2"}} className='text-center'>Customization</h3>
                    <p className='text-center' >
                    We understand that every business is unique, which is why we offer customized solutions tailored to your specific needs, goals, and budget.
                    </p>
                </div>
                <div className='Maincon3_container_sub'>
                <div className='my-3 text-center'>
                        <span style={{borderRadius:"20rem", backgroundColor:"#003bb2",paddingTop:"1.3rem",paddingBottom:"1.5rem",paddingLeft:"1rem",paddingRight:"1rem"}}>
                            <FaRegThumbsUp fontSize={40} color='white' />
                        </span>
                    </div>
                <h3 style={{color:"#003bb2"}} className='text-center'>Client Satisfaction</h3>
                    <p className='text-center' >
 We prioritize clear communication, transparency, and responsiveness throughout every project, ensuring that your experience with us is seamless and enjoyable.
                 </p>

                </div>
            </div>

        </div>

    </div>
  )
}

export default Maincon3