import React from 'react'
import img from '../image/background5.png'

function Example({text}) {
  return (
   
      <img
        className="d-block w-100"
        src={img}
        alt={text}
        style={{height:"35rem"}}
      />

  )
}

export default Example