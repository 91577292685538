import React from "react";

function Maincon4() {
  return (
    <div className="continer my-5">
      <h1 className="text-center"><span style={{color:"#003bb2",borderBottom:"3px solid #003bb2"}} className="pb-1">Get Started Today</span></h1>
      <p className="p-3">
        Ready to take your business to new heights? Contact us today to learn
        more about our services and how we can help you achieve your digital
        marketing and development goals. Whether you're a small startup or a
        large enterprise, we have the expertise and resources to propel your
        business forward in the digital age.
      </p>
      <div className="d-flex justify-content-center">
        <a href="https://kanakdrishtiinfo.com/contact">
      <button className="btn btn-outline-primary btn-lg">Request a Quote</button>
        </a>
      </div>
    </div>
  );
}

export default Maincon4;
